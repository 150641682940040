// src/gatsby-plugin-theme-ui/index.js
export default {
  breakpoints: ["40em", "56em", "64em", "90em"],
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  fonts: {
    body:
      '"Barlow", -apple-system, BlinkMacSystemFont, avenir next, avenir, helvetica neue, helvetica, Ubuntu, roboto, noto, segoe ui, arial, sans-serif',
    heading:
      '"Barlow", -apple-system, BlinkMacSystemFont, avenir next, avenir, helvetica neue, helvetica, Ubuntu, roboto, noto, segoe ui, arial, sans-serif',
    monospace:
      "Menlo, Consolas, Monaco, Liberation Mono, Lucida Console, monospace",
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 96],
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
    extraBold: 900,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  colors: {
    headingText: "#2B311F",
    text: "#1C2F26",
    background: "#fff",
    coverBackground: "#CDCEC3",
    headerBg: "rgb(14, 18, 4)",
    homeHeadingText: "#eee",
    homeBodyText: "#777",
    footerLinkcolor: "#587715",
    footerTextcolor: "#555",
  },
  buttons: {
    primary: {
      backgroundColor: "#000",
      color: "#F2FFD7",
      appearance: "none",
    },
    secondary: {
      appearance: "none",
      borderRadius: "8px",
      backgroundColor: "#fff",
      color: "#333",
      border: "1px solid #ddd",
      lineHeight: "normal",
      "&:hover": {
        color: "#2c4cc8",
        backgroundColor: "#fff",
      },
    },
    icon: {
      borderRadius: "8px",
      backgroundColor: "#fff",
      color: "rgba(0,0,0,0.6)",
      border: "1px solid #ddd",
      p: 3,
      fontSize: [1],
      "&:hover": {
        color: "#2c4cc8",
        backgroundColor: "#fff",
      },
    },
  },
  cards: {
    primary: {
      borderRadius: 16,
      backgroundColor: "#fff",
      transition: "box-shadow .3s linear",
      boxShadow: "0 0 1px rgba(0,0,0,0.3)",
      "&:hover": {
        boxShadow: "1px 5px 30px 3px #A4A897",
      },
    },
  },
  variants: {
    container: {
      maxWidth: "1440px",
      m: "0 auto",
      pt: [3, 4, 4, 5],
      px: [3, 4, 4, 5],
    },
    headerContainer: {
      maxWidth: "1440px",
      m: "0 auto",
      px: [3, 4, 4, 5],
    },
    featHome: {
      maxWidth: "1440px",
      m: "0 auto",
      px: [3, 4, 4, 5],
    },
    paginationButton: {
      cursor: "pointer",
      bg: "#0b1207",
      color: "#fff",
      fontWeight: "600",
      padding: "32px 64px",
      margin: "64px auto 0",
      "&:hover": {
        bg: "#333",
        color: "#fff",
      },
    },
  },
}
